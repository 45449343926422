import React, { useEffect, useRef, useState } from "react"
import {
  Button,
  Divider,
  Grid, 
  LinearProgress, 
  Paper, 
  Typography, 
} from "@mui/material"
import { 
  // DeleteWithConfimrationIcon,
  ScrollingList, 
  useEnduserSession, 
  useFiles, 
  useFormResponses, 
  useResolvedSession, 
  useTellescopeForm,
  value_is_loaded,
  TellescopeSingleQuestionFlow,
  elapsed_time_display_string,
  LoadingLinear,
} from "@tellescope/react-components"
import { Form, FormField, FormResponse } from "@tellescope/types-client"
import { routes, useNavigateToPage } from "../../definitions/routes"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { FormIcon, FormOpenIcon, WithCircularBackground } from "../../definitions/icons"
import { DASHBOARD_CHILD_MARGIN } from "../../definitions/constants"
import { useOrganizationTheme } from "../../definitions/contexts"
import { TitleWithFirstName } from "../../components/titles"
import { FileCardContent, Files } from "./Files"
import { ResponseCardContent, SubmittedForms } from "./SubmittedForms"
import { get_prepopulated_responses } from "@tellescope/utilities"

export const FormResponsePreview = ({ item: response } : { item: FormResponse }) => {
  const navigate = useNavigateToPage()
  const completed = !!response.submittedAt
  return (
    <Grid container alignItems="center" wrap="nowrap"
      onClick={() => {
        if (completed) return
        navigate(routes.forms, { id: response.accessCode })
      }}
    >
      <Grid item>
        {completed
          ? <FormIcon color="success" />
          : <FormOpenIcon color="primary" />
        }
      </Grid>

      <Grid item direction="column" sx={{ ml: 1 }}>
        <Typography color="primary" style={{ 
          fontWeight: 'bold', 
          fontSize: 20,
        }}>
          {response.formTitle}
          {completed && <Typography sx={{ fontSize: 14 }}>Submitted {elapsed_time_display_string(new Date(response.submittedAt!))}</Typography>}
        </Typography>
      </Grid>
    </Grid>
  )
}

export const FormForLoadedData = ({ accessCode, response, fields, form } : { accessCode: string, response: FormResponse, form: Form, fields: FormField[] }) => {
  const session = useEnduserSession()
  const { theme } = useOrganizationTheme()
  const navigate = useNavigateToPage()

  const [, { filtered }] = useFormResponses({
    loadFilter: {
      ...(response?.groupId ? { groupId: response?.groupId } : { }),
      ...(response?.groupInstance ? { groupInstance: response?.groupInstance } : { }),
    }
  })
  const unsubmittedGroupLoading = filtered(f => 
    !f.submittedAt
    && (!!(response?.groupId && response?.groupInstance))
    && f.groupId === response.groupId
    && f.groupInstance === response.groupInstance
    && f.id !== response?.id // not same form
  )
  const unsubmitted = value_is_loaded(unsubmittedGroupLoading) ? unsubmittedGroupLoading.value : []
  const nextresponse = unsubmitted[0]

  try {
    const formProps = useTellescopeForm({
      ...response,
      existingResponses: (
        response?.responses?.length 
          ? response.responses
          : get_prepopulated_responses(fields, session.userInfo)
      ),
      accessCode,
      fields,
      form,
      enduser: session.userInfo,
    })

    return (
      <Grid container justifyContent="center">
      <Grid container direction="column" sx={{
        maxWidth: 600,
        p: 2,
      }}>
        <Grid item sx={{ my: 2 }}>
        <Grid container direction="column" alignItems="center">
          <Grid item sx={{ width: 38, height: 38, mb: 2 }}>
          <WithCircularBackground>
            <FormIcon />
          </WithCircularBackground>
          </Grid>

          <Typography sx={{ fontSize: 24, fontWeight: 'bold', mb: 2 }}>
            {form.title}
          </Typography>

          {form.description && 
            <Typography sx={{ fontSize: 16, opacity: 0.7, textAlign: 'center', mb: 2 }}>
              {form.description}
            </Typography>
          }

          <Divider flexItem sx={{ my: 2 }} />
        </Grid>
        </Grid>

        <TellescopeSingleQuestionFlow
          {...formProps} form={form} groupId={response.groupId} groupInstance={response.groupInstance}
          theme={theme}
          onSuccess={() => {
            if (nextresponse?.accessCode) {
              return window.location.href = window.location.href.replace(accessCode, nextresponse.accessCode)
            }
            navigate(routes.documents)
          }} 
          inputStyle={{
            marginBottom: '15px'
          }}
        />
      </Grid>
      </Grid>
    )
  } catch(err: any) {
    const message = (
      typeof err === 'string'
        ? err
    : typeof err?.message === 'string'
        ? err.message
        : 'An error occurred'
    )
    return (
      <Typography>
        {message === "Root not found for given fields"
          ? "Start question not found for form"
          : message
        } 
      </Typography>
    )
  }  
}

export const SubmitForm = () => {
  const session = useEnduserSession()
  const navigate = useNavigateToPage()
  
  const accessCode = useParams().accessCode!

  const [info, setInfo] = useState<undefined | { response: FormResponse, fields: FormField[], form: Form }>(undefined)
  const fetchRef = useRef(false)

  useEffect(() => {
    if (fetchRef.current) return
    fetchRef.current = true

    session.api.form_responses.info_for_access_code({ accessCode })
    .then(setInfo)
    .catch((err: any) => {
      alert(err.message)
      navigate(routes.documents) 
    })
  }, [fetchRef, session, accessCode, navigate])

  if (!info) return <LinearProgress />
  return (
    <FormForLoadedData accessCode={accessCode} {...info} />
  )
}

export const HandleFormGroup = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams() 
  const groupId = params.get('groupId')
  const groupInstance = params.get('groupInstance') || undefined

  const [, { filtered }] = useFormResponses({
    dontFetch: !groupId,
    loadFilter: {
      ...(groupId ? { groupId } : { }),
      ...(groupInstance ? { groupInstance } : { }),
      submittedAt: { _exists: false }
    }
  })
  const unsubmittedGroupLoading = filtered(f => 
    !f.submittedAt
    && (!!groupId) && f.groupId === groupId
    && (!groupInstance || f.groupInstance === groupInstance)
  )
  const unsubmitted = value_is_loaded(unsubmittedGroupLoading) ? unsubmittedGroupLoading.value : undefined
  
  useEffect(() => {
    if (!unsubmitted?.length) return

    navigate(`/documents/forms/${unsubmitted[0].accessCode}`, { })
  }, [unsubmitted, navigate])

  return <FilesAndFormsList />
}

export const FilesAndFormsList = () => {
  const navigate = useNavigateToPage()
  const session = useResolvedSession()
  const [, { filtered: filteredFiles, doneLoading: doneLoadingFiles, loadMore: loadMoreFiles } ] = useFiles()
  const pushedFilesLoading = filteredFiles(f => !!f.pushedToClientPortal)

  const [, { filtered: filteredResponses, doneLoading: doneLoadingResponses, loadMore: loadMoreResponses }] = useFormResponses({ }) // loads unsubmitted forms as well
  const formResponsesLoading = filteredResponses(f => 
    !f.hideFromEnduserPortal
    && !(!f.submittedAt && !!f.isInternalNote) // hide unsubmitted internal notes from portal
    && !(f.draftSavedAt && !f.submittedAt) // is a draft (only users, not endusers can save as draft)
    && (
      f.submittedBy === session.userInfo.id  // form submitted by enduser
    || (!f.submittedBy && !f.draftSavedAt) // form outstanding (and not saved draft by user)
    || (!!f.responses.find(r => r.sharedWithEnduser === true))
    )
  )
 
  // if using files and forms in same list
  // const doneLoading = useCallback(() => 
  //   doneLoadingResponses() && doneLoadingFiles(), 
  //   [doneLoadingFiles, doneLoadingResponses]
  // )
  // const loadMore = useCallback(async () => {
  //   if (!doneLoadingFiles()) { loadMoreFiles().catch(console.error) };
  //   if (!doneLoadingResponses()) { loadMoreResponses().catch(console.error) };
  // }, [doneLoadingFiles, doneLoadingResponses, loadMoreFiles, loadMoreResponses])

  const outstandingForms = (
    value_is_loaded(formResponsesLoading) 
      ? formResponsesLoading.value.filter(f => !f.submittedAt && !f.isInternalNote && f.source !== 'Formsort') 
      : [] 
  )

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item sx={{ my: 3, textAlign: 'center' }}>
        <TitleWithFirstName ending="Important Documents" />
      </Grid>

      <LoadingLinear data={pushedFilesLoading} render={files => 
        files.length === 0 
          ? <></>
          : (
            <>
            <ScrollingList items={files.slice(0, 3)} 
              doneLoading={doneLoadingFiles} loadMore={loadMoreFiles}
              title="Shared Files" emptyText=""
              titleStyle={{ paddingLeft: DASHBOARD_CHILD_MARGIN }}
              itemContainerStyle={{
                padding: DASHBOARD_CHILD_MARGIN,
                paddingTop: 2,
              }}
              Item={({ item }) => (
                <Paper elevation={5} sx={{ padding: 2, borderRadius: 4, marginBottom: 1 }}>
                  <FileCardContent file={item} />
                </Paper>
              )}
            />

            {files.length > 3 && 
              <Button variant="outlined" onClick={() => navigate(routes.files)} sx={{ width: 200, borderRadius: 3 }}>
                View All Files
              </Button>
            }
            </>
          )
        } 
      />
      
      {outstandingForms.length > 0 &&
        <Grid container sx={{ mb: 4 }}>
        <ScrollingList items={outstandingForms} 
          doneLoading={doneLoadingResponses} loadMore={loadMoreResponses}
          maxHeight={'50vh'} 
          title="Outstanding Forms" emptyText=""
          titleStyle={{
            paddingLeft: DASHBOARD_CHILD_MARGIN,
            paddingRight: DASHBOARD_CHILD_MARGIN,
            marginBottom: 0,
          }}
          itemContainerStyle={{
            paddingTop: 2,
            paddingLeft: DASHBOARD_CHILD_MARGIN,
            paddingRight: DASHBOARD_CHILD_MARGIN,
          }}
          Item={({ item }) => (
            <Paper elevation={5} sx={{ padding: 2, borderRadius: 4, marginBottom: 1 }}>
              <ResponseCardContent response={item} />
            </Paper>
          )}
        />
        </Grid>
      }

      <SubmittedForms limit={3} />

      <Files limit={3} />
  </Grid>
  )
}

export const Documents = () => <HandleFormGroup />
// (
//   <Grid container style={dashboardChildStyles}>
//   <TabNavigation tabs={[
//     { label: "Form Responses", component: <FormResponses /> },
//     { label: "Files", component: <Files /> },
//   ]}/>
//   </Grid>
// )