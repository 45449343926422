import React, { useCallback, useEffect, useRef, useState } from "react"
import { 
  Autocomplete,
  Grid, 
  LinearProgress, 
  TextField, 
  Typography,
} from "@mui/material"
import { 
  DisplayNameForUser,
  DisplayPictureForUser,
  HoverPaper,
  LoadingButton,
  LoadingLinear,
  ScrollingList,
  Styled,
  useAppointmentBookingPages,
  useEnduserSession,
  useEnduserSessionContext,
  useHandleError,
  useResolvedSession,
  useUsers, 
} from "@tellescope/react-components"
import { useParams, useSearchParams } from "react-router-dom"
import { useFullHeight } from "../components/navigation"
import { routes, useNavigateToPage } from "../definitions/routes"
import { DASHBOARD_CHILD_MARGIN } from "../definitions/constants"
import { AppointmentBookingPage as AppointmentBookingPageType, CalendarEventTemplate } from "@tellescope/types-client"
import { BaseAvailabilityBlock } from "@tellescope/types-models"
import { fullMonth_day_year, replace_tag_template_values_for_enduser, time_for_calendar_event } from "@tellescope/utilities"
import { VALID_STATES } from "@tellescope/validation"
import { ResponsiveModal } from "../components/layout"
import { AppointmentBookingPage } from "./AppointmentBooking/AppointmentBooking"

export const StateInput = ({ 
  value,
  onChange,
} : {
  value?: string,
  onChange: (s: string) => void
}) => {
  return (
    <Autocomplete
      disablePortal
      id="state-search-in"
      options={VALID_STATES}
      value={value}
      disableClearable
      onChange={(e, v) => v && onChange(v)}
      renderInput={(params) => <TextField {...params} label="State" />}
    />
  )
}

export const SetEnduserStateForm = ({ style } : Styled) => {
  const { enduserSession: session, updateUserInfo } = useEnduserSessionContext()
  const [state, setState] = useState(session.userInfo.state)

  return (
    <Grid container alignItems="center" justifyContent="center" style={style}>
    <Grid container direction="column" style={{ maxWidth: 400 }}>
      <Typography sx={{ mb: '2px', fontSize: 17 }}>
        Which state are you a resident of?
      </Typography>
      
      <StateInput value={state} onChange={setState} />

      <LoadingButton submitText="Save" submittingText="Saving..." 
        disabled={!state}
        onClick={() => updateUserInfo({ state })}
      />
    </Grid>
    </Grid>
  )
}

export const AppointmentSchedulingForEventTemplate = ({ template } : { template: CalendarEventTemplate }) => {
  const navigate = useNavigateToPage()
  const session = useEnduserSession()
  const [, { findById: findUser }] = useUsers()

  const [availabilities, setAvailabilities] = useState<BaseAvailabilityBlock[]>([])
  const fetchRef = useRef<'loading' | 'fetching' | 'loaded'>('loading')
  const { handleAPIError, errorDisplay } = useHandleError()

  const [selectedAvailability, setSelectedAvailability] = useState<BaseAvailabilityBlock | undefined>()

  useEffect(() => {
    if (fetchRef.current !== 'loading') return
    fetchRef.current = 'fetching'

    handleAPIError(async () => {
      const { availabilityBlocks } = await session.api.calendar_events.get_appointment_availability({
        calendarEventTemplateId: template.id,
        restrictedByState: template.restrictedByState,
        from: new Date(),
      })
      setAvailabilities(availabilityBlocks)
      fetchRef.current = 'loaded'
    }) 
  }, [session, handleAPIError, template])

  const handleLoadMore = useCallback(async () => {
    if (availabilities.length === 0) return
    const mostRecentSlot = (
      [...availabilities].sort((a1, a2) => a1.startTimeInMS - a2.startTimeInMS).pop()!
    )

    const mostRecentStartTimeInMS = mostRecentSlot.startTimeInMS + mostRecentSlot.durationInMinutes * 60 * 1000

    // error handled by LoadingButton
    const { availabilityBlocks } = await session.api.calendar_events.get_appointment_availability({
      calendarEventTemplateId: template.id,
      restrictedByState: template.restrictedByState,
      from: new Date(mostRecentStartTimeInMS + 1), // add 1 to avoid loading duplicate slots
    })

    // append
    setAvailabilities(as => [...as, ...availabilityBlocks])
  }, [availabilities, session, template])

  return (
    <Grid container direction="column">
      <ResponsiveModal open={!!selectedAvailability} setOpen={b => setSelectedAvailability(a => b ? a : undefined)}
        style={{ maxWidth: 550, minHeight: 400 }}
      >
      {selectedAvailability &&
      <Grid container alignItems="center" justifyContent="center" direction="column" spacing={1}>
        <Grid item>
          <Typography sx={{ fontWeight: 'bold', fontSize: 22 }}>
            {template.title}
          </Typography>
        </Grid>
        <Grid item>
        <Typography component="span" sx={{ fontSize: 18 }}>
          {fullMonth_day_year(new Date(selectedAvailability.startTimeInMS))} 
          <span> {time_for_calendar_event(selectedAvailability)}</span>
        </Typography>
        </Grid>

        <Grid item>
        <Grid container alignItems="center">
          <DisplayPictureForUser id={selectedAvailability.userId} size={45} style={{ marginRight: 8 }} />

          <Grid item>
          <Grid container direction="column">
            <DisplayNameForUser id={selectedAvailability.userId} />
            {(findUser(selectedAvailability.userId)?.suffixes?.length ?? 0) > 0 && 
              <Typography style={{ fontSize: 14 }}>
                {findUser(selectedAvailability.userId)!.suffixes!.join(', ')}
              </Typography>
            }
          </Grid>
          </Grid> 
        </Grid>
        </Grid>

        <Grid item sx={{ mt: 3 }}>
          <LoadingButton submitText="Book Appointment" submittingText="Booking..."
            style={{ maxWidth: 250 }}
            onClick={async () => {
              const { createdEvent } = await session.api.calendar_events.book_appointment({
                calendarEventTemplateId: template.id,
                startTime: new Date(selectedAvailability.startTimeInMS),
                userId: selectedAvailability.userId,
              })

              navigate(routes.events, { id: createdEvent.id, query: { status: 'self-scheduled-success' }})
            }}
          />
        </Grid>
        {errorDisplay}
      </Grid>
      }
      </ResponsiveModal>
      {errorDisplay}

      <ScrollingList
        title={template.title} items={availabilities.map(a => ({ ...a, id: a.userId + a.startTimeInMS }))}
        emptyText="There are no available appointments for this time period"
        Item={({ item: availability }) => (
          <HoverPaper sx={{ m: 1, ml: 0, p: 2 }}
            onClick={() => setSelectedAvailability(availability)}
          >
          <Grid container direction="column">
            <Grid item>
            <Typography component="span" sx={{ fontWeight: 'bold' }}>
              {fullMonth_day_year(new Date(availability.startTimeInMS))} 
              <span> {time_for_calendar_event(availability)}</span>
            </Typography>
            </Grid>

            <Grid item sx={{ mt: 0.5 }}>
            <Grid container alignItems="center">
              <DisplayPictureForUser id={availability.userId} size={35} style={{ marginRight: 8 }} />

              <Grid item>
              <Grid container direction="column">
                <DisplayNameForUser id={availability.userId} />
                {(findUser(availability.userId)?.suffixes?.length ?? 0) > 0 && 
                  <Typography style={{ fontSize: 14 }}>
                    {findUser(availability.userId)!.suffixes!.join(', ')}
                  </Typography>
                }
              </Grid>
              </Grid>
            </Grid>
            </Grid>
          </Grid>
          </HoverPaper>
        )}
      />
      <LoadingButton submitText="Load More" submittingText="Loading..."
        onClick={handleLoadMore}
        style={{ maxWidth: 250 }}
      />
    </Grid>
  )
}

export const AppointmentSchedulingForBookingPageIdId = ({ bookingPage, bookingPageId } : { bookingPage?: AppointmentBookingPageType | null, bookingPageId: string }) => {
  const session = useEnduserSession()
  const [queries] = useSearchParams()

  const userId = queries.get('userId') || undefined
  const skipState = queries.get('skipState') === "true"
  const formResponseId = queries.get('formResponseId') || undefined
  const scheduledBy = queries.get('scheduledBy') || undefined
  const holdAppointmentMinutes = queries.get('holdAppointmentMinutes') ? parseInt(queries.get('holdAppointmentMinutes')!) : undefined

  const userIdsString = queries.get('userIds') || undefined
  let userIds: string[] = []
  try {
    userIds = (
      userIdsString 
        ? userIdsString.split(',')
        : []
    )
    if (!Array.isArray(userIds)) {
      userIds = []
    } else {
      userIds = userIds.filter(u => typeof u === 'string')
    }
  } catch(err) {
    console.error(err)
  }

  const fieldsString = queries.get('fields') || undefined
  let fields = undefined as undefined | Record<string, string>
  try {
    fields = fieldsString ? JSON.parse(fieldsString) : undefined
  } catch(err) {}

  const userTagsString = queries.get('userTags') || undefined
  let userTags: string[] = []
  try {
    userTags = (
      userTagsString 
        ? userTagsString.split(',')
        : []
    )
    if (!Array.isArray(userTags)) {
      userTags = []
    } else {
      userTags = userTags.filter(u => typeof u === 'string')
    }
  } catch(err) {
    console.error(err)
  }

  const userFilterTagsString = queries.get('userFilterTags') || undefined
  let userFilterTags: string[] = []
  try {
    userFilterTags = (
      userFilterTagsString 
        ? userFilterTagsString.split(',')
        : []
    )
    if (!Array.isArray(userFilterTags)) {
      userFilterTags = []
    } else {
      userFilterTags = userFilterTags.filter(u => typeof u === 'string')
    }
  } catch(err) {
    console.error(err)
  }
  if (bookingPage?.limitedByTagsPortal?.length) {
    userFilterTags.push(
      ...replace_tag_template_values_for_enduser(bookingPage.limitedByTagsPortal, session.userInfo)
    )
  }

  return (
    <AppointmentBookingPage fields={fields} userId={userId} scheduledBy={scheduledBy}
      businessId={session.userInfo.businessId}
      appointmentBookingPageId={bookingPageId}
      skipState={skipState}
      userIds={userIds} userTags={userTags} userFilterTags={userFilterTags}
      formResponseId={formResponseId} holdAppointmentMinutes={holdAppointmentMinutes}
    />
  )
}
export const AppointmentSchedulingForTemplateRoute = () => {
  const session = useResolvedSession()
  const pageId = useParams().pageId!

  const [bookingPage, setBookingPage] = useState<AppointmentBookingPageType | null>()
  const onceRef = useRef(false)
  useEffect(() => {
    if (onceRef.current) return
    onceRef.current = true

    session.api.appointment_booking_pages.getOne(pageId)
    .then(setBookingPage)
    .catch(() => setBookingPage(null))
  }, [session, pageId])

  if (bookingPage === undefined) return <LinearProgress />
  return (
    <AppointmentSchedulingForBookingPageIdId bookingPage={bookingPage} bookingPageId={pageId} />
  )
}

export const AppointmentScheduling = () => {
  const [, { filtered, ...loadProps }] = useAppointmentBookingPages()

  const navigate = useNavigateToPage()
  const maxHeight = useFullHeight()
  
  return (
    <Grid container sx={{ m: DASHBOARD_CHILD_MARGIN }}>
    <LoadingLinear data={filtered(p => !p.hiddenFromPortal)} render={pages => (
    <ScrollingList maxHeight={maxHeight} {...loadProps}
      title="Appointments" items={pages}
      emptyText="There are no available appointment booking pages"
      Item={({ item: page }) => (
        <HoverPaper sx={{ m: 1, ml: 0, p: 2 }}  
          onClick={() => navigate(routes.appointment_booking, { id: page.id })}
        >
        <Grid container direction="column">
          <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>
            {page.title}
          </Typography>
        </Grid>
        </HoverPaper>
      )}
    />
    )} />
    </Grid>
  )
}